import { useEffect } from 'react'
import { LazyRouteFunction, RouteObject } from 'react-router-dom'

import ErrorBoundary from 'routes/ErrorBoundary'

function Reloader (): null {
  useEffect(() => {
    console.debug('Reloading to get new code')
    location.reload()
  }, [])
  return null
}

export function getRouteObjects (opts?: {
  useMockLoaders?: boolean
}): RouteObject[] {
  const useMockLoaders = opts?.useMockLoaders ?? false

  function configureModule<
    M extends Awaited<ReturnType<LazyRouteFunction<RouteObject>>> & {
      mockLoader?: RouteObject['loader']
    }
  > (loadModule: () => Promise<M>): LazyRouteFunction<RouteObject> {
    return async () => {
      try {
        const module = await loadModule()
        return useMockLoaders && module.mockLoader !== undefined
          ? {
              ...module,
              loader: module.mockLoader
            }
          : module
      } catch (err) {
        console.warn('Unable to load route module', err)
        return {
          Component: Reloader
        }
      }
    }
  }

  return [
    {
      path: '',
      errorElement: <ErrorBoundary />,
      lazy: configureModule(async () => await import('routes/RootRoute')),
      children: [
        {
          index: true,
          lazy: configureModule(async () => await import('routes/IndexRoute'))
        },
        {
          path: 'auth',
          children: [
            {
              index: true,
              lazy: configureModule(
                async () => await import('routes/auth/IndexRoute')
              )
            },
            {
              path: 'emailed',
              lazy: configureModule(
                async () => await import('routes/auth/emailed/IndexRoute')
              )
            },
            {
              path: 'signout',
              lazy: configureModule(
                async () => await import('routes/auth/signout/IndexRoute')
              )
            }
          ]
        },
        {
          path: 'sign',
          children: [
            {
              path: ':workspaceId/:contractId',
              lazy: configureModule(
                async () => await import('routes/sign/contract/RootRoute')
              ),
              children: [
                {
                  index: true,
                  lazy: configureModule(
                    async () => await import('routes/sign/contract/IndexRoute')
                  )
                },
                {
                  path: ':signerId',
                  lazy: configureModule(
                    async () => await import('routes/sign/contract/signer/RootRoute')
                  ),
                  children: [
                    {
                      path: 'review',
                      lazy: configureModule(
                        async () =>
                          await import('routes/sign/contract/signer/ReviewRoute')
                      )
                    },
                    {
                      index: true,
                      lazy: configureModule(
                        async () =>
                          await import('routes/sign/contract/signer/FillRoute')
                      )
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'workspaces',
          lazy: configureModule(
            async () => await import('routes/workspaces/RootRoute')
          ),
          children: [
            {
              index: true,
              lazy: configureModule(
                async () => await import('routes/workspaces/IndexRoute')
              )
            },
            {
              path: 'create',
              lazy: configureModule(
                async () => await import('routes/workspaces/CreateRoute')
              )
            },
            {
              path: 'delete/:workspaceId',
              lazy: configureModule(
                async () => await import('routes/workspaces/DeleteRoute')
              )
            },
            {
              path: ':workspaceId',
              lazy: configureModule(
                async () => await import('routes/workspaces/GetRoute')
              ),
              children: [
                {
                  path: 'settings/:category?/:section?',
                  lazy: configureModule(
                    async () =>
                      await import('routes/workspaces/settings/IndexRoute')
                  )
                },
                {
                  path: 'templates/:templateId',
                  lazy: configureModule(
                    async () =>
                      await import('routes/workspaces/templates/GetRoute')
                  ),
                  children: [
                    {
                      path: 'edit',
                      lazy: configureModule(
                        async () =>
                          await import('routes/workspaces/templates/EditRoute')
                      )
                    },
                    {
                      path: 'compose',
                      lazy: configureModule(
                        async () =>
                          await import(
                            'routes/workspaces/templates/ComposeRoute'
                          )
                      )
                    }
                  ]
                },
                {
                  path: ':section',
                  lazy: configureModule(
                    async () =>
                      await import('routes/workspaces/contracts/RootRoute')
                  ),
                  children: [
                    {
                      path: ':contractId?',
                      lazy: configureModule(
                        async () =>
                          await import('routes/workspaces/contracts/IndexRoute')
                      )
                    },
                    {
                      path: ':contractId/edit',
                      lazy: configureModule(
                        async () =>
                          await import('routes/workspaces/contracts/EditRoute')
                      )
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'admin',
          lazy: configureModule(
            async () => await import('routes/admin/RootRoute')
          ),
          children: [
            {
              index: true,
              lazy: configureModule(
                async () => await import('routes/admin/IndexRoute')
              )
            },
            {
              path: 'users',
              lazy: configureModule(
                async () => await import('routes/admin/users/IndexRoute')
              )
            },
            {
              path: 'workspaces',
              lazy: configureModule(
                async () => await import('routes/admin/workspaces/RootRoute')
              ),
              children: [
                {
                  path: ':workspaceId',
                  lazy: configureModule(
                    async () => await import('routes/admin/workspaces/GetRoute')
                  ),
                  children: [
                    {
                      path: 'templates/:templateId',
                      lazy: configureModule(
                        async () =>
                          await import(
                            'routes/admin/workspaces/templates/GetRoute'
                          )
                      )
                    },
                    {
                      path: 'contracts/:contractId',
                      lazy: configureModule(
                        async () =>
                          await import(
                            'routes/admin/workspaces/contracts/GetRoute'
                          )
                      )
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}
